import {
  MonitorDownIcon,
  PodcastIcon,
  SofaIcon,
  StoreIcon,
  VenetianMaskIcon,
} from 'lucide-react';

//////////////// CUSTOMER PERSONAS //////////////////
// Used in the "Who is this for?" section of the homepage

export const CUSTOMER_PERSONAS = [
  {
    name: 'Podcasters',
    slug: 'podcasters',
    color: '#f0812c',
    icon: PodcastIcon,
  },
  {
    name: 'Editors',
    slug: 'editors',
    color: '#4CAF50',
    icon: MonitorDownIcon,
  },
  {
    name: 'Marketers',
    slug: 'marketers',
    color: '#e71010',
    icon: StoreIcon,
  },
  {
    name: 'Agencies',
    slug: 'agencies',
    color: '#2196F3',
    icon: VenetianMaskIcon,
  },
  {
    name: 'Studios',
    slug: 'podcast-studios',
    color: '#9C27B0',
    icon: SofaIcon,
  },
];

//////////////// COPY //////////////////

export const TITLES = ['AI-Powered Clip Maker for Creators'];

export const DESCRIPTIONS = [
  'ClipFM turns long-form video into short clips for social media. We use AI to find the best moments of your video and give you ready-to-post clips within minutes.',
];

export const HERO_TITLES = [
  // To the point
  'Automatic clipping for long form video',
  // Focus: Speed
  "The world's fastest clip creator.",
  'The fastest way to make clips from your videos.',
  // Focus: Simplicity
  'Turn podcasts into viral clips with one click.',
  'Turn your content into engaging clips, without the hassle.',
  // Focus: Repurposing
  'Get more from your content.',
  'Turn podcasts into viral clips with one click.',
  'Turn long videos into viral clips in seconds.',
];

export const HERO_SUBTITLES = [
  'We use AI to automatically find the best moments of your video and give you ready-to-post clips within minutes.',
  'Powered by AI, our professional studio finds the best clips from your content and lets you customize in seconds.',
  'Powered by AI, ClipFM Studio finds the best clips from your content and lets you customize in seconds.',
  'Powered by AI, our clip studio finds the best moments from your content and lets you customize & export in seconds.',
];

export const COPY = {
  0: {
    title: TITLES[0],
    description: DESCRIPTIONS[0],
    hero_title: HERO_TITLES[0],
    hero_subtitle: HERO_SUBTITLES[0],
  },
  1: {
    title: TITLES[0],
    description: DESCRIPTIONS[0],
    hero_title: HERO_TITLES[6],
    hero_subtitle: HERO_SUBTITLES[3],
  },
};
