export const SITE_URL = process.env.NEXT_PUBLIC_SITE_URL || 'https://clip.fm';

export const COPY = {
  BASIC_TAGLINE: `AI-Powered Clip Making Software`,
  BASIC_DESCRIPTION:
    // 'An AI tool that generates video clips for YouTube Shorts, Instagram Reels, and TikTok. Spend less time editing and pay only a fraction of what it would cost to hire a video editor.',
    'ClipFM turns long-form video into short clips for social media. We use AI to find the best moments of your video and give you ready-to-post clips within minutes.',
};

export const IMAGES = {
  BASIC_META: SITE_URL + '/meta.png',
  BLOG_COVER: SITE_URL + '/images/cover-images/cover-1.jpg',
};

export const CONTACT_EMAIL = 'cole@clip.fm';


export const CTA_VERSION = 1;
export const CTA_HEADERS = {
  heroSection: [

  ],
  getStartedSection: [
    'Get started making clips today.'
  ]
}