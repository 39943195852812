import clsx from 'clsx';
import Link from 'next/link';
import * as React from 'react';

const MainButton: React.FC<{
  href?: string;
  onClick?: any;
  className?: any;
  children: any;
}> = ({ href, onClick, children, ...props }) => {
  const className = clsx(
    props.className,
    'rounded-full bg-black px-6 font-bold text-white hover:opacity-[0.85]'
  );

  // USE LINK
  if (href) {
    return (
      <Link legacyBehavior href={href} target="_blank" rel="noreferrer">
        <button
          className={clsx(className, 'inline-flex items-center justify-center')}
          onClick={onClick}
        >
          {children}
        </button>
      </Link>
    );
  }

  // USE BUTTON
  return (
    <button type='submit' onClick={onClick} className={className}>
      {children}
    </button>
  );
};

export default MainButton;
