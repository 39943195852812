import { AppProps } from 'next/app';
import Script from 'next/script';
import { DefaultSeo } from 'next-seo';

import '@/styles/globals.css';

import WaitlistModal from '@/components/modals/WaitlistModal';

import { DEFAULT_SEO } from '@/content/seo';
import { mukta } from '@/styles/fonts';

function MyApp({ Component, pageProps }: AppProps) {
  return (
    <>
      {/* SEO (needs to be first) */}
      <DefaultSeo {...DEFAULT_SEO} />
      <Script
        strategy='lazyOnload'
        src={`https://www.googletagmanager.com/gtag/js?id=${process.env.NEXT_PUBLIC_GOOGLE_ANALYTICS}`}
      />
      <Script strategy='lazyOnload' id='google-analytics'>
        {`
          window.dataLayer = window.dataLayer || [];
          function gtag(){dataLayer.push(arguments);}
          gtag('js', new Date());
          gtag('config', '${process.env.NEXT_PUBLIC_GOOGLE_ANALYTICS}', {
          page_path: window.location.pathname,
          });
        `}
      </Script>
      <Script id='segment-source'>
        {`
          !function(){var analytics=window.analytics=window.analytics||[];if(!analytics.initialize)if(analytics.invoked)window.console&&console.error&&console.error("Segment snippet included twice.");else{analytics.invoked=!0;analytics.methods=["trackSubmit","trackClick","trackLink","trackForm","pageview","identify","reset","group","track","ready","alias","debug","page","once","off","on","addSourceMiddleware","addIntegrationMiddleware","setAnonymousId","addDestinationMiddleware"];analytics.factory=function(e){return function(){if(window.analytics.initialized)return window.analytics[e].apply(window.analytics,arguments);var i=Array.prototype.slice.call(arguments);i.unshift(e);analytics.push(i);return analytics}};for(var i=0;i<analytics.methods.length;i++){var key=analytics.methods[i];analytics[key]=analytics.factory(key)}analytics.load=function(key,i){var t=document.createElement("script");t.type="text/javascript";t.async=!0;t.src="https://cdn.segment.com/analytics.js/v1/" + key + "/analytics.min.js";var n=document.getElementsByTagName("script")[0];n.parentNode.insertBefore(t,n);analytics._loadOptions=i};analytics._writeKey="8l0eD5dR8UvJowtLKxitOJnhzIVTk4DV";;analytics.SNIPPET_VERSION="4.16.1";
          analytics.load("8l0eD5dR8UvJowtLKxitOJnhzIVTk4DV");
          analytics.page();
          }}();
        `}
      </Script>
      {/* Content */}
      <div className={mukta.className}>
        <Component {...pageProps} />
      </div>
      {/* Modals */}
      <WaitlistModal />
      <Script strategy='lazyOnload' id='hotjar-tracking'>
        {`
          (function(h,o,t,j,a,r){
              h.hj=h.hj||function(){(h.hj.q=h.hj.q||[]).push(arguments)};
              h._hjSettings={hjid:3521494,hjsv:6};
              a=o.getElementsByTagName('head')[0];
              r=o.createElement('script');r.async=1;
              r.src=t+h._hjSettings.hjid+j+h._hjSettings.hjsv;
              a.appendChild(r);
          })(window,document,'https://static.hotjar.com/c/hotjar-','.js?sv=');
        `}
      </Script>
    </>
  );
}

export default MyApp;
