import { Dialog } from '@headlessui/react';
import clsx from 'clsx';
import { useAtom } from 'jotai';
import { FC } from 'types';

import WaitlistSignupForm from '@/components/old/WaitlistSignupForm';

import { waitlistModalAtom } from '@/state';

/**
 * Simple modal to show the waitlist signup form.
 */
const WaitlistModal: FC<any> = () => {
  const [isOpen, setIsOpen] = useAtom(waitlistModalAtom);
  return (
    <Dialog
      open={isOpen}
      onClose={() => setIsOpen(false)}
      className={clsx('relative z-50')}
    >
      {/* The backdrop, rendered as a fixed sibling to the panel container */}
      <div className='fixed inset-0 bg-black/50' aria-hidden='true' />

      {/* Full-screen container to center the panel */}
      <div className='fixed inset-0'>
        <Dialog.Panel
          className={clsx(
            'mx-auto flex max-w-xl flex-col items-center justify-center space-y-4 bg-white p-8 shadow-2xl xl:rounded-lg',
            'h-screen w-full rounded-none px-4 md:mt-[calc(50vh-200px)] md:h-auto'
          )}
        >
          <Dialog.Title>Join the waitlist.</Dialog.Title>
          <Dialog.Description>
            <WaitlistSignupForm />
          </Dialog.Description>
        </Dialog.Panel>
      </div>
    </Dialog>
  );
};

export default WaitlistModal;
