import { atom } from 'jotai';

// Whether the Demo signup modal is open.
export const demoModalAtom = atom(false);

// Whether the Waitlist signup modal is open.
// This just holds the singular "email" field.
export const waitlistModalAtom = atom(false);

// After signing up with an email, offer to let users
// move up to the f
export const waitlistProfileModalAtom = atom(false);
