import { CircularProgress } from '@mui/material';
import axios from 'axios';
import { useFormik } from 'formik';
import { useAtom } from 'jotai';
import { useRouter } from 'next/router';
import { useRef } from 'react';
import { FC } from 'types';

import MainButton from '@/components/buttons/MainButton';

import { waitlistModalAtom } from '@/state';

const WaitlistSignupForm: FC<any> = () => {
  const [_, setIsOpen] = useAtom(waitlistModalAtom);
  const inputRef = useRef<HTMLInputElement | null>(null);
  const router = useRouter();
  const formik = useFormik({
    initialValues: {
      email: '',
    },
    onSubmit: async (values) => {
      if (!values.email) {
        inputRef.current?.focus();
        return;
      }
      try {
        await axios.post('/api/signup', {
          email: values.email,
        });
        setIsOpen(false);
        router.push(`/waitlist?e=${values.email}`);
      } catch (error) {
        console.error(error);
        throw error;
      }
    },
  });

  return (
    <form
      onSubmit={formik.handleSubmit}
      className='relative space-y-2 md:space-y-0'
    >
      <input
        ref={inputRef}
        name='email'
        type='email'
        value={formik.values.email}
        onChange={formik.handleChange}
        className='h-[68px] w-full max-w-full rounded-full border-slate-200 pl-6 hover:border-slate-400 focus:border-slate-500 md:pr-64'
        placeholder='Enter your email'
      />
      <MainButton className='right-[1px] top-[1px] h-[66px] w-full md:absolute md:w-52'>
        {formik.isSubmitting ? (
          <CircularProgress
            size={28}
            thickness={4}
            sx={{ mt: 0.5, color: 'white' }}
          />
        ) : (
          '🚀 Join the waitlist'
        )}
      </MainButton>
    </form>
  );
};

export default WaitlistSignupForm;
