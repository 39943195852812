import { NextSeoProps } from 'next-seo';
import {SITE_URL} from '../lib/constants';

import { IMAGES } from '@/lib/constants';

import { COPY } from '@/content/copy';

export const DEFAULT_SEO: Partial<NextSeoProps> = {
  titleTemplate: '%s | ClipFM',
  defaultTitle: 'ClipFM',
  description: COPY[0].description,
  openGraph: {
    title: 'ClipFM',
    siteName: 'ClipFM',
    type: 'website',
    url: SITE_URL,
    images: [
      {
        url: IMAGES.BASIC_META,
        width: 1200,
        height: 628,
        type: 'image/jpeg',
      },
    ],
  },
  twitter: {
    handle: '@clipdotfm',
    site: '@clipdotfm',
    cardType: 'summary_large_image',
  },
};
